// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info_bot {
    margin-top: 25%;
    text-align: center;
    justify-content: center;
    background-size: cover;
}

.tgBot a{
    font-size: 25px;
    font-weight: 500;
    color: black !important;
    text-decoration: none
}`, "",{"version":3,"sources":["webpack://./src/components/tgBot/tgBot.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB;AACJ","sourcesContent":[".info_bot {\r\n    margin-top: 25%;\r\n    text-align: center;\r\n    justify-content: center;\r\n    background-size: cover;\r\n}\r\n\r\n.tgBot a{\r\n    font-size: 25px;\r\n    font-weight: 500;\r\n    color: black !important;\r\n    text-decoration: none\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
