// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    height: 1000px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.username {
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".header {\r\n    width: 100%;\r\n    height: 1000px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 0 20px;\r\n}\r\n\r\n.username {\r\n    margin-left: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
