import React from "react";
import Button from "../Button/Button";
import { useTelegram } from "../../hooks/useTelegram";
import DropDown from '../Dropdown/Dropdown'
import { schedule } from '../Schedule/Schedule'
import "./Header.css";
// import Homepage from "../Pages/Home";

const Header = () => {
    return (
			<header>
				<DropDown/>
				<div className={'header'}>
				</div>
			</header>
		)
};

export default Header