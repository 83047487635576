// useLoadData.js
import { useEffect, useState } from 'react';
import { useDatabase } from '../hooks/database' // Импортируйте ваш хук

const useLoadData = () => {
	const { loading, error} = useDatabase()
	const [initialLoading, setInitialLoading] = useState(true)
	const [errorMessage, setErrorMessage] = useState(null)

	useEffect(() => {
		if (error) {
			setErrorMessage(error.message)
		if (!loading) {
			setInitialLoading(false)
		}
		}
	}, [loading, error])
	return { initialLoading, loading, errorMessage}
}

export default useLoadData
