import React, { useEffect } from "react";

export const useClickOutside = (ref, callback) => {
    const handleClick = (e) => {
        if (
                !ref.current.contains(e.target) && !e.target.classList.contains('menu_button')
            ) {
                callback()
            }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return() => {
            document.removeEventListener("mousedown", handleClick)
        };
    });
}