// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-screen {
  display: flex;
  flex-direction: column; /* Вертикальное выравнивание */
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--tg-theme-secondary-bg-color);
  font-family: Arial, sans-serif; /* Шрифт для текста */
  color: var(--tg-theme-text-color); /* Цвет текста */
}

.spinner {
  width: 60px;
  height: 60px;
  border: 5px solid var(--tg-theme-section-bg-color);
  border-radius: 50%;
  border-top-color: var(--tg-theme-text-color);
  animation: spin 1.2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  font-size: 18px; /* Размер текста */
  text-align: center; /* Центрирование текста */
}
`, "",{"version":3,"sources":["webpack://./src/Server/reload.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB,EAAE,8BAA8B;EACtD,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,oDAAoD;EACpD,8BAA8B,EAAE,qBAAqB;EACrD,iCAAiC,EAAE,gBAAgB;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kDAAkD;EAClD,kBAAkB;EAClB,4CAA4C;EAC5C,oCAAoC;EACpC,mBAAmB;AACrB;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,eAAe,EAAE,kBAAkB;EACnC,kBAAkB,EAAE,yBAAyB;AAC/C","sourcesContent":[".loading-screen {\r\n  display: flex;\r\n  flex-direction: column; /* Вертикальное выравнивание */\r\n  justify-content: center;\r\n  align-items: center;\r\n  height: 100vh;\r\n  background-color: var(--tg-theme-secondary-bg-color);\r\n  font-family: Arial, sans-serif; /* Шрифт для текста */\r\n  color: var(--tg-theme-text-color); /* Цвет текста */\r\n}\r\n\r\n.spinner {\r\n  width: 60px;\r\n  height: 60px;\r\n  border: 5px solid var(--tg-theme-section-bg-color);\r\n  border-radius: 50%;\r\n  border-top-color: var(--tg-theme-text-color);\r\n  animation: spin 1.2s linear infinite;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n@keyframes spin {\r\n  0% { transform: rotate(0deg); }\r\n  100% { transform: rotate(360deg); }\r\n}\r\n\r\n.loading-text {\r\n  font-size: 18px; /* Размер текста */\r\n  text-align: center; /* Центрирование текста */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
