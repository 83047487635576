import {AsiecData} from '../../Server/AsiecData'
import { getAllItems } from '../../Parser/AsiecParserItems'
import { useCallback } from 'react'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Flip, ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDatabase } from '../../hooks/database'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import './Schedule.css'
import { getScheduleAsiecParser } from '../../Parser/AsiecParserSchedule'
import { LoadingScreen } from '../../Server/reload'

function Schedule() {
	const { loading, errorMessage, user_group } = useDatabase()
	const [selectedValue, setSelectedValue] = useState('grup')
	const [getItem, setItem] = useState(user_group)
	const currentDate = new Date()
	const futureDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000)
	const date_1 = currentDate.toISOString().slice(0, 10)
	const date_2 = futureDate.toISOString().slice(0, 10)
	let groups = Object.keys(AsiecData.grup)
	let teacher = Object.keys(AsiecData.prep)
	let classroom = Object.keys(AsiecData.aud)
	const [startDate, setStartDate] = useState(date_1)
	const [endDate, setEndDate] = useState(date_2)
	const [lessons, setLessons] = useState()
	const [groupUser, setGroupUser] = useState()
	const URL_HOOK = process.env.REACT_APP_URL_HOOK

	const submit_button = useCallback(async () => {
		const url = `${URL_HOOK}api/getSchedule/`
		try {
			const schedule = (
				await axios.post(url, {
					data: {
						item: selectedValue,
						item_id: AsiecData[selectedValue][getItem],
						date_1: startDate,
						date_2: endDate,
					},
				})
			).data
			setLessons(schedule)
		} catch (error) {
			setLessons({})
		}
	})

	useEffect(() => {
		setItem(user_group)
		setGroupUser(user_group)
	}, [user_group])

	useEffect(() => {
		if(getItem){
			submit_button()
		}
	}, [groupUser])

	if (loading) {
		return <LoadingScreen />
	}

	

	const showToastMessage = () => {
		toast.error('Ошибка в дате!', {
			position: 'top-right',
			autoClose: 700,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			transition: Flip,
		})
	}

	const handleEndDateChange = e => {
		const selectedEndDate = e.target.value
		if (selectedEndDate >= startDate) {
			setEndDate(selectedEndDate)
		} else {
			showToastMessage()
		}
	}

	const handleStartDateChange = e => {
		const selectedStartDate = e.target.value
		if (selectedStartDate <= endDate) {
			setStartDate(selectedStartDate)
		} else {
			showToastMessage()
		}
	}

	const handleChange = event => {
		setSelectedValue(event.target.value)
		if (event.target.value == 'grup') {
			setItem(user_group)
		}
		if (event.target.value == 'prep') {
			setItem(teacher[0])
		}
		if (event.target.value == 'aud') {
			setItem(classroom[0])
		}
	}

	const itemChange = event => {
		setItem(event.target.value)
	}
	const getSchedule = () => {
		if (!lessons) {
			return <LoadingScreen />
		}

		if (Object.keys(lessons['lessons']).length === 0) {
			return <p>Расписания нет</p>
		}
		
		return (
			<>
				<div className='schedule-type'>
					<p>{'Тип расписания: ' + lessons.type}</p>
				</div>

				{Object.keys(lessons['lessons']).map((lesson, index) => (
					<div key={'Date_' + index} className='schedule'>
						<div className='date'>
							<p>{lesson}</p>
						</div>

						<div className='schedule-lessons'>
							{lessons['lessons'][lesson].map((info, index) => (
								<div key={'lesson_' + index} className='lesson'>
									<div className='lesson-info'>
										<p>{info['Number']}</p>
										<p>{'Группа: ' + info['Group']}</p>
										<p>{'Дисциплина: ' + info['Name']}</p>
										<p>{'Преподаватель: ' + info['Teacher']}</p>
										<p>{'Аудитория: ' + info['Classroom']}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</>
		)
	}

	const renderContent = () => {
		switch (selectedValue) {
			case 'grup':
				return (
					<>
						<p>Группа:</p>
						<select value={getItem} onChange={itemChange}>
							{groups.map((item, index) => (
								<option key={index}>{item}</option>
							))}
						</select>
					</>
				)
			case 'prep':
				return (
					<>
						<p>Преподаватель:</p>
						<select value={getItem} onChange={itemChange}>
							{teacher.map((item, index) => (
								<option key={index}>{item}</option>
							))}
						</select>
					</>
				)
			case 'aud':
				return (
					<>
						<p>Аудитория:</p>
						<select value={getItem} onChange={itemChange}>
							{classroom.map((item, index) => (
								<option key={index}>{item}</option>
							))}
						</select>
					</>
				)
			default:
				return null
		}
	}

	return (
		<header className='schedule'>
			<div className='setting-schedule'>
				<div className='button-wrapper'>
					<p>Расписание по:</p>
					<select value={selectedValue} onChange={handleChange}>
						<option value='grup'>Группе</option>
						<option value='prep'>Преподавателю</option>
						<option value='aud'>Аудитории</option>
					</select>
				</div>
				<div className='content'>{renderContent()}</div>
				<div className='input-calendar'>
					<input
						type='date'
						id='calendar'
						value={startDate}
						onChange={handleStartDateChange} // Обновление состояния при изменении
					/>
					<input
						type='date'
						id='calendar2'
						value={endDate}
						onChange={handleEndDateChange} // Обновление состояния при изменении
					/>
				</div>
				<button className='button' onClick={() => submit_button()}>
					Выбрать
				</button>
			</div>
			
			<div className='schedule-info'>{getSchedule()}</div>

			<ToastContainer limit={2} />
		</header>
	)
}

export default Schedule
