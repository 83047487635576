import React, { useEffect, useState } from 'react';
import "./tgBot.css";
import qr_code from "./qr-code.png"

const TgBot = () => {
    return (
			<div className='info_bot'>
				<p className='qr_code'>
					<a href='https://t.me/StudentAssistant_asiec_bot' target='_blank'>
						<img src={qr_code} width='264' height='264' border='0' />
					</a>
				</p>
				<p className='tgBot'>
					<a href='https://t.me/StudentAssistant_asiec_bot' target='_blank'>
						@StudentAssistant_asiec_bot
					</a>
				</p>
				
			</div>
		)
};

export default TgBot
