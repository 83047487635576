const tg = window.Telegram.WebApp

export function useTelegram() {
	const tg = window.Telegram.WebApp

	const onClose = () => {
		tg.close()
	}

	return {
		onClose,
		tg,
		user: tg.initDataUnsafe?.user,
		user_id: tg.initDataUnsafe?.user.id,
	}
}
