import { useEffect } from 'react';
import './App.css';
import { useTelegram } from './hooks/useTelegram';
import Header from './components/Header/Header';
import DropDown from './components/Dropdown/Dropdown';
import TgBot from './components/tgBot/tgBot';
import { Router, Route, Routes } from 'react-router-dom'
import Schedule from './components/Schedule/Schedule'
import HomeWork from './components/HomeWork/HomeWork'
import useLoadData from './Server/useLoadData' // Импортируйте ваш хук
import { useDatabase } from './hooks/database';
import { Flip, ToastContainer, toast } from 'react-toastify'
import { LoadingScreen } from './Server/reload';
import 'react-toastify/dist/ReactToastify.css'


const ErrorNotification = () =>
	toast.error("[Ошибка] Повторите попытку позже", {
		position: 'top-center',
		autoClose: 700,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		transition: Flip,
	})

const tg = window.Telegram.WebApp;

function App() {
  if(tg.initDataUnsafe.user) {
	const { loading, errorMessage } = useDatabase()

	// if (loading) {
	// 	return <LoadingScreen />
	// }

	// if (errorMessage) {
	// 	ErrorNotification()
	// 	return (
	// 		<>
	// 			<LoadingScreen />
	// 			<ToastContainer limit={2} />
	// 		</>
	// 	)
	// }

    return (
			<div className='App'>
				<DropDown />
				<Routes>
					<Route exact path='/homework' element={<HomeWork />} />
					<Route exact path='/' element={<Schedule />} />
				</Routes>
			</div>
		)
  } else {
    return (
      <>
        <div>
          <TgBot />
          
        </div>
      </>
      
			
		)

  }
  
}

export default App;
