import "./reload.css"
import reload_app from "./reloadapp.png"

export function LoadingScreen() {
    return (
        <div className='loading-screen'>
		    <div className='spinner'></div>
			<p className='loading-text'>Загрузка, пожалуйста подождите...</p>
	    </div>
    )
}

