import axios from 'axios'
import { useState, useEffect } from 'react'
import { useTelegram } from './useTelegram'
import CryptoJS from 'crypto-js'
const DB_URL = process.env.REACT_APP_DB_URL
const secretKey = process.env.REACT_APP_SECRET_KEY

export function useDatabase() {
	const { user } = useTelegram()
	const [data, setData] = useState(null)
	const [dataHW, setDataHW] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true) // Состояние загрузки
	const cipherText = CryptoJS.AES.encrypt(String(user.id), secretKey).toString()
	useEffect(() => {
		const fetchData = async () => {
			if (loading) {
				try {
					const response = await axios.post(DB_URL, { 
                        data: {token: cipherText}})

					const fetchedData = response.data.users[user.id]

					const group = fetchedData.user_group
					const fetchedDataHW = response.data.homework[group]
					setData(fetchedData)
					setDataHW(fetchedDataHW)

				} catch (error) {
					setError(error)
				} finally {
					setLoading(false)

				}
			} else {
				return 
			}
			
		}

		fetchData()
		
	}, [error, loading, dataHW, data]) // Добавляем user.id в зависимости, чтобы обновлять данные при изменении пользователя

	if (loading) {
		return { loading: true } // Возвращаем состояние загрузки
	}

	if (error) {
		return { name: null, error }
	}
	
	if (!data) {
		return { name: null } // Данные ещё не загружены
	}

	return {
		loading: false,
		error: false,
		user_id: data.user_id,
		name: data.name,
		user_group: data.user_group,
		subjects: dataHW,
	}
}
