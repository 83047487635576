import React, {useState, useRef} from "react";
import "./Dropdown.css";
import {
	BsPersonCircle,
	BsFillPersonFill,
	BsCalendar,
	BsFillBellFill,
	BsFillGearFill,
	BsBoxArrowRight,
	BsBook,
} from 'react-icons/bs'
import { useClickOutside } from "../useClickOutside";
import { LoadingScreen } from "../../Server/reload";
import useLoadData from "../../Server/useLoadData";
import { useTelegram } from "../../hooks/useTelegram";
import { useDatabase } from '../../hooks/database';
import { useNavigate } from "react-router-dom";

const DropDown = () => {
	const { user, onClose } = useTelegram()
	const {  name } = useDatabase()

	

    const [isOpen, setOpen] = useState(false);
	const menuRef = useRef(null)
	useClickOutside(menuRef, ()=> setOpen(false))
	const navigate = useNavigate() // Инициализируем navigate

	const handleMenuItemClick = path => {
		navigate(path) // Переход по указанному пути
		setOpen(false) // Закрываем меню после выбора
	}

	// if (loading) {
	// 	return <LoadingScreen />
	// }

	// if (errorMessage) {
	// 	ErrorNotification()
	// 	return (
	// 		<>
	// 			<LoadingScreen />
	// 			<ToastContainer limit={2} />
	// 		</>
	// 	)
	// }

    return (
			<div className='menu_app'>
				<button className='menu_button' onClick={() => setOpen(!isOpen)}>
					<BsPersonCircle className='menu_button' />
				</button>
				<span className='username'>{name}</span>
				<nav ref={menuRef} className={`menu ${isOpen ? 'active' : ''}`}>
					<ul className='menu_list'>
						<li className='menu_item' onClick={() => handleMenuItemClick('/')}>
							<BsCalendar className='icon' />
							<span>Расписание</span>
						</li>
						<li className='menu_item' onClick={() => handleMenuItemClick('/homework')}>
							<BsBook className='icon' />
							<span>Домашка</span>
						</li>
						<li className='menu_item' onClick={onClose}>
							<BsBoxArrowRight className='icon' />
							<span>Выйти</span>
						</li>
					</ul>
				</nav>
			</div>
		)
};

export default DropDown;