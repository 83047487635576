import axios from 'axios'
import { useState, useEffect } from 'react'

export function getScheduleAsiecParser(dataItem) {
    // const dataItem = {
	// 		item: 'grup',
	// 		item_id: '71d4f046-3cc0-11ee-9626-00155d879809',
    //         date_1: "12.03.2024",
    //         date_2: "20.03.2024"
	// 	}
	const [getSchedule, setSchedule] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true) // Состояние загрузки
	const url = 'https://6whf5llv-4080.euw.devtunnels.ms/api/getSchedule/'
	useEffect(() => {
		const fetchData = async () => {
			if (loading) {
				try {
					const schedule = (await axios.post(url, { 
                        data: {item: dataItem.item,
                        item_id: dataItem.item_id,
                        date_1: dataItem.date_1, 
                        date_2: dataItem.date_2} })).data
                    setSchedule(schedule)
				} catch (error) {
					setError(error)
				} finally {
					setLoading(false)
				}
			} else {
				return
			}
		}

		fetchData()
	}, [error, loading, getSchedule]) // Добавляем user.id в зависимости, чтобы обновлять данные при изменении пользователя

	if (loading) {
		return { loading: true } // Возвращаем состояние загрузки
	}

	if (error) {
		return { name: null, error }
	}
	return {
		parserLoading: false,
		parserError: false,
		lesson: getSchedule,
	}
}
