import axios from 'axios'
import { useState, useEffect } from 'react'

export function getAllItems() {
	const [getGroup, setGroup] = useState(null)
	const [getTeacher, setTeacher] = useState(null)
	const [getClassroom, setClassroom] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true) // Состояние загрузки
	const url = 'https://6whf5llv-4080.euw.devtunnels.ms/api/getItems/'
	useEffect(() => {
		const fetchData = async () => {
			if (loading) {
				try {
					const group = (await axios.post(url, {data: 'grup'})).data
					const teacher = (await axios.post(url, { data: 'prep' })).data
					const classroom = await (await axios.post(url, { data: 'aud' })).data
					setGroup(group)
					setTeacher(teacher)
					setClassroom(classroom)

				} catch (error) {
					setError(error)
				} finally {
					setLoading(false)

				}
			} else {
				return 
			}
			
		}

		fetchData()
		
	}, [error, loading, getGroup, getTeacher, getClassroom]) // Добавляем user.id в зависимости, чтобы обновлять данные при изменении пользователя

	if (loading) {
		return { loading: true } // Возвращаем состояние загрузки
	}

	if (error) {
		return { name: null, error }
	}

	return {
		parserLoading: false,
		parserError: false,
		group: getGroup,
		teacher: getTeacher,
		classroom: getClassroom
	}
}


















// export async function getAllItems() {
// 	const [getItemInfo, setItemInfo] = useState(null)
// 	const url = 'https://6whf5llv-4080.euw.devtunnels.ms/api/getItems'
// 	try {
// 		const response = await axios.post(
// 			url,
// 			{
// 				data: 'grup', // Передача значения 'item'
// 				headers: {
// 					'Content-Type': 'application/json',
// 				},
// 			})
// 		console.log(response.data)
// 		setItemInfo(response.data)
// 	} catch (error) {
// 		console.error('Ошибка при получении данных:', error)
// 		return { '11Б211': '98dc3d20-04aa-11ec-8a63-00155d879809' } // Вернуть пустой объект в случае ошибки
// 	}
// 	return { items: getItemInfo }
// }
		

	
