import React, { useState } from 'react'
import axios from 'axios'
import { useTelegram } from '../../hooks/useTelegram'
import { useDatabase } from '../../hooks/database'
import useLoadData from '../../Server/useLoadData'
import { LoadingScreen } from '../../Server/reload'
import './HomeWork.css'


function HomeWork() {
	const { loading, user_group, subjects } = useDatabase()
	const [selectedSubject, setSelectedSubject] = useState('')
	const URL_HOOK = process.env.REACT_APP_URL_HOOK
	const handleSubjectChange = event => {
		setSelectedSubject(event.target.value)
	}

	if (loading) {
		return <LoadingScreen />
	}

	const getHomework = () => {
		if (!selectedSubject) {
			return
		}
		return (
			<>
				<div className='homework-info-date'>
					<p>{subjects[selectedSubject].date}</p>
				</div>
				<div className='homework-info-box'>
					<div className='homework-info-subject'>
						<p>Добавил: {subjects[selectedSubject].adder_name}</p>
						<p>Группа: {user_group} </p>
						<p>Предмет: {selectedSubject}</p>
						<p>Описание: {subjects[selectedSubject].description}</p>
					</div>
				</div>
			</>
		)
	}

	const getHomeworkPhoto = () => {
		if (subjects[selectedSubject] && subjects[selectedSubject].photo) {
			return (
				<>
					<p>Медиа:</p>
					<div className='homework-info-photo'>
					{subjects[selectedSubject].photo.split(' ').map((item, index) => (
						<div className='img'>
							<img
								src={`${URL_HOOK}photo/${item.split('/')[0]}`}
								alt=''
								onClick={() => {
									document.querySelector('.pop-up').style.display = 'block';
									document.querySelector(
										'.pop-up img'
									).src = `${URL_HOOK}photo/${item.split('/')[0]}`
								}}
                        	/>
						</div>
					))}
					</div>
					<div className='pop-up'>
						<span
							onClick={() => {
								document.querySelector('.pop-up').style.display = 'none'
							}}
						>
							&times;
						</span>
						<img src="" alt="" />
					</div>
				</>
				
				
			)
		}
	}
	
	const getHomeworkFile = () => {
		 if (subjects[selectedSubject] && subjects[selectedSubject].document) {
        return (
					<>
						<p>Файлы:</p>
						<div className='homework-info-document'>
							{subjects[selectedSubject].document
								.split(' ')
								.map((item, index) => (
									<div className='doc' key={index}>
										<span>
											<a
												href={`${URL_HOOK}document/${item.split('/')[0]}`}
												target='_blank'
												rel='noopener noreferrer'
												className='document-link'
											>
												Документ {index + 1}
											</a>
										</span>
									</div>
								))}
						</div>
					</>
				)
    }
}

	const listSubjects = () => {
		if (subjects) {
			return (<>
						<p>Домашка по:</p>
						<select value={selectedSubject} onChange={handleSubjectChange}>
							<option value='' disabled>
								Выберите предмет
							</option>
							{Object.keys(subjects).map((item, index) => (
								<option key={index}>{item}</option>
							))}
						</select>
					</>)
		
	}	else {
		return <p>Домашки нет</p>
	} 
	}
	return (
		<header className='homework'>
			<div className='homework-subject'>{listSubjects()}</div>
			{subjects && (
				<>
					<div className='homework-info'>{getHomework()}</div>
					<div className='homework-container'>{getHomeworkPhoto()}</div>
					<div className='homework-container-file'>{getHomeworkFile()}</div>
				</>
				
			)}
			
		</header>
	)
}

export default HomeWork
